import { ServiceFactory } from '@/services/ServiceFactory'
// const _productsService = ServiceFactory.get('ProductsService')
const _barAreaService = ServiceFactory.get('BarAreasService')
const _tablesService = ServiceFactory.get('TablesService')
export default {
  data () {
    return {
      loadingTable: false,
      headers: [
        {
          text: 'Imagen',
          value: 'image',
          sortable: false
        },
        {
          text: 'Nombre',
          value: 'name',
          sortable: false
        },
        {
          text: 'Precio',
          value: 'price',
          sortable: false
        },
        {
          text: 'Descripcion',
          value: 'description',
          sortable: false
        },
        {
          text: 'Editar/Borrar/Disponible',
          value: 'options',
          sortable: false
        }
      ],
      page: 1,
      dialog: false,
      products: [],
      categories: [
        {
          name: 'Comida',
          value: 'comida'
        },
        {
          name: 'Bebida',
          value: 'bebida'
        }
      ],
      postDataBarArea: {
        name: '',
        zbBarId: ''
      },
      barAreas: [],
      dialogTables: false,
      postTable: {
        zbBarAreaId: '',
        numberChairs: 0,
        numberTable: 0
      },
      loading: false
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id, 'Bar area')
        try {
          this.barAreas = await _barAreaService.show(this.$route.params.id)
          // this.products = await _productsService.index()
          // console.log(this.barAreas)
        } catch (error) {
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        this.$router.push({ name: 'companies' })
      }
    },
    async submitData () {
      // console.log(this.postDataBarArea)
      this.loading = true
      if (this.$route.params.id) {
        try {
          this.postDataBarArea.zbBarId = this.$route.params.id
          this.postBarArea = await _barAreaService.create(this.postDataBarArea)
          // console.log(this.postProduct, 'Guardado con exito')
          this.initialize()
          this.loading = false
          this.dialog = false
          this.$notify({
            type: 'success',
            text: 'Se agregó correctamente el ambiente'
          })
        } catch (error) {
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al agregar el ambiente'
          })
          // console.info(error)
          this.loading = false
          this.dialog = false
        }
      } else {
        // console.log('Sin datos del negocio')
        // this.loadingForm = false
      }
    },
    editCategory (id) {
      // console.log(id)
      this.$router.push({ name: 'member-form', params: { id } })
    },
    destroyCategory (id) {
      this.$swal({
        title: '¿Está seguro de eliminar la categoria?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // await _newsCategoriesService.destroy(id)
            this.$swal(
              'Categoría eliminada',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo eliminar.'
            })
          }
        }
      })
    },
    goNewForm (id, payload = null) {
      // console.log(id)
      // this.$router.push({ name: 'member-form', params: { type: 'addMember', id } })
    },
    async openAddTable (barAreaId) {
      this.postTable.zbBarAreaId = barAreaId
      this.dialogTables = true
    },
    async submitTable () {
      this.loadingTable = true
      if (this.$route.params.id) {
        try {
          this.postTable.numberChairs = parseInt(this.postTable.numberChairs)
          this.postTable.numberTable = parseInt(this.postTable.numberTable)
          // console.log(this.postTable)
          await _tablesService.create(this.postTable)
          this.loadingTable = false
          // console.log('Guardado con exito')
          this.dialog = false
          this.$notify({
            type: 'success',
            text: 'Se agregó correctamente la mesa para el ambiente'
          })
          this.initialize()
        } catch (error) {
          // console.info(error)
          this.loadingTable = false
          this.$notify({
            type: 'error',
            text: 'Ocurrió un error al agregar la mesa'
          })
        }
      } else {
        // console.log('Sin datos del negocio')
        // this.loadingForm = false
      }
    }
  }
}
